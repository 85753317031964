<template>
  <CCard>
    <CCardHeader><h1>Bienvenido</h1></CCardHeader>
    <CCardBody>
      <p>
        Hola {{ user.name }}, te presentamos la nueva aplicación After Sales
        Reporting Tool.
      </p>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "WelcomeCard",
  data() {
    return {};
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
